const loginUrl = process.env.NODE_ENV == "development" ? "https://localhost:7285/Login?ReturnUrl=http://localhost:3000" : "https://auth.oc6.dk/Login?ReturnUrl=https://b.oc6.dk";

export class AuthenticatedFetchHandler {
    public static async fetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
        init = {
            ...init ?? {},
            credentials: "include"
        };

        const response = await window.fetch(input, init);

        if (response.status == 401) {
            //redirect to login
            window.location.href = loginUrl;
        }

        return response;
    }
}