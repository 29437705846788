export const Classes: (...classes: string[]) => string = (...classes: string[]) => classes.join(" ");

/*Loaded from: https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css*/

export enum BS {
    h1 = "h1",
    h2 = "h2",
    h3 = "h3",
    h4 = "h4",
    h5 = "h5",
    h6 = "h6",
    small = "small",
    mark = "mark",
    lead = "lead",
    display_1 = "display-1",
    display_2 = "display-2",
    display_3 = "display-3",
    display_4 = "display-4",
    display_5 = "display-5",
    display_6 = "display-6",
    list_unstyled = "list-unstyled",
    list_inline = "list-inline",
    list_inline_item = "list-inline-item",
    initialism = "initialism",
    blockquote = "blockquote",
    blockquote_footer = "blockquote-footer",
    img_fluid = "img-fluid",
    img_thumbnail = "img-thumbnail",
    figure = "figure",
    figure_img = "figure-img",
    figure_caption = "figure-caption",
    container = "container",
    container_fluid = "container-fluid",
    container_lg = "container-lg",
    container_md = "container-md",
    container_sm = "container-sm",
    container_xl = "container-xl",
    container_xxl = "container-xxl",
    row = "row",
    col = "col",
    row_cols_auto = "row-cols-auto",
    row_cols_1 = "row-cols-1",
    row_cols_2 = "row-cols-2",
    row_cols_3 = "row-cols-3",
    row_cols_4 = "row-cols-4",
    row_cols_5 = "row-cols-5",
    row_cols_6 = "row-cols-6",
    col_auto = "col-auto",
    col_1 = "col-1",
    col_2 = "col-2",
    col_3 = "col-3",
    col_4 = "col-4",
    col_5 = "col-5",
    col_6 = "col-6",
    col_7 = "col-7",
    col_8 = "col-8",
    col_9 = "col-9",
    col_10 = "col-10",
    col_11 = "col-11",
    col_12 = "col-12",
    offset_1 = "offset-1",
    offset_2 = "offset-2",
    offset_3 = "offset-3",
    offset_4 = "offset-4",
    offset_5 = "offset-5",
    offset_6 = "offset-6",
    offset_7 = "offset-7",
    offset_8 = "offset-8",
    offset_9 = "offset-9",
    offset_10 = "offset-10",
    offset_11 = "offset-11",
    g_0 = "g-0",
    gx_0 = "gx-0",
    gy_0 = "gy-0",
    g_1 = "g-1",
    gx_1 = "gx-1",
    gy_1 = "gy-1",
    g_2 = "g-2",
    gx_2 = "gx-2",
    gy_2 = "gy-2",
    g_3 = "g-3",
    gx_3 = "gx-3",
    gy_3 = "gy-3",
    g_4 = "g-4",
    gx_4 = "gx-4",
    gy_4 = "gy-4",
    g_5 = "g-5",
    gx_5 = "gx-5",
    gy_5 = "gy-5",
    col_sm = "col-sm",
    row_cols_sm_auto = "row-cols-sm-auto",
    row_cols_sm_1 = "row-cols-sm-1",
    row_cols_sm_2 = "row-cols-sm-2",
    row_cols_sm_3 = "row-cols-sm-3",
    row_cols_sm_4 = "row-cols-sm-4",
    row_cols_sm_5 = "row-cols-sm-5",
    row_cols_sm_6 = "row-cols-sm-6",
    col_sm_auto = "col-sm-auto",
    col_sm_1 = "col-sm-1",
    col_sm_2 = "col-sm-2",
    col_sm_3 = "col-sm-3",
    col_sm_4 = "col-sm-4",
    col_sm_5 = "col-sm-5",
    col_sm_6 = "col-sm-6",
    col_sm_7 = "col-sm-7",
    col_sm_8 = "col-sm-8",
    col_sm_9 = "col-sm-9",
    col_sm_10 = "col-sm-10",
    col_sm_11 = "col-sm-11",
    col_sm_12 = "col-sm-12",
    offset_sm_0 = "offset-sm-0",
    offset_sm_1 = "offset-sm-1",
    offset_sm_2 = "offset-sm-2",
    offset_sm_3 = "offset-sm-3",
    offset_sm_4 = "offset-sm-4",
    offset_sm_5 = "offset-sm-5",
    offset_sm_6 = "offset-sm-6",
    offset_sm_7 = "offset-sm-7",
    offset_sm_8 = "offset-sm-8",
    offset_sm_9 = "offset-sm-9",
    offset_sm_10 = "offset-sm-10",
    offset_sm_11 = "offset-sm-11",
    g_sm_0 = "g-sm-0",
    gx_sm_0 = "gx-sm-0",
    gy_sm_0 = "gy-sm-0",
    g_sm_1 = "g-sm-1",
    gx_sm_1 = "gx-sm-1",
    gy_sm_1 = "gy-sm-1",
    g_sm_2 = "g-sm-2",
    gx_sm_2 = "gx-sm-2",
    gy_sm_2 = "gy-sm-2",
    g_sm_3 = "g-sm-3",
    gx_sm_3 = "gx-sm-3",
    gy_sm_3 = "gy-sm-3",
    g_sm_4 = "g-sm-4",
    gx_sm_4 = "gx-sm-4",
    gy_sm_4 = "gy-sm-4",
    g_sm_5 = "g-sm-5",
    gx_sm_5 = "gx-sm-5",
    gy_sm_5 = "gy-sm-5",
    col_md = "col-md",
    row_cols_md_auto = "row-cols-md-auto",
    row_cols_md_1 = "row-cols-md-1",
    row_cols_md_2 = "row-cols-md-2",
    row_cols_md_3 = "row-cols-md-3",
    row_cols_md_4 = "row-cols-md-4",
    row_cols_md_5 = "row-cols-md-5",
    row_cols_md_6 = "row-cols-md-6",
    col_md_auto = "col-md-auto",
    col_md_1 = "col-md-1",
    col_md_2 = "col-md-2",
    col_md_3 = "col-md-3",
    col_md_4 = "col-md-4",
    col_md_5 = "col-md-5",
    col_md_6 = "col-md-6",
    col_md_7 = "col-md-7",
    col_md_8 = "col-md-8",
    col_md_9 = "col-md-9",
    col_md_10 = "col-md-10",
    col_md_11 = "col-md-11",
    col_md_12 = "col-md-12",
    offset_md_0 = "offset-md-0",
    offset_md_1 = "offset-md-1",
    offset_md_2 = "offset-md-2",
    offset_md_3 = "offset-md-3",
    offset_md_4 = "offset-md-4",
    offset_md_5 = "offset-md-5",
    offset_md_6 = "offset-md-6",
    offset_md_7 = "offset-md-7",
    offset_md_8 = "offset-md-8",
    offset_md_9 = "offset-md-9",
    offset_md_10 = "offset-md-10",
    offset_md_11 = "offset-md-11",
    g_md_0 = "g-md-0",
    gx_md_0 = "gx-md-0",
    gy_md_0 = "gy-md-0",
    g_md_1 = "g-md-1",
    gx_md_1 = "gx-md-1",
    gy_md_1 = "gy-md-1",
    g_md_2 = "g-md-2",
    gx_md_2 = "gx-md-2",
    gy_md_2 = "gy-md-2",
    g_md_3 = "g-md-3",
    gx_md_3 = "gx-md-3",
    gy_md_3 = "gy-md-3",
    g_md_4 = "g-md-4",
    gx_md_4 = "gx-md-4",
    gy_md_4 = "gy-md-4",
    g_md_5 = "g-md-5",
    gx_md_5 = "gx-md-5",
    gy_md_5 = "gy-md-5",
    col_lg = "col-lg",
    row_cols_lg_auto = "row-cols-lg-auto",
    row_cols_lg_1 = "row-cols-lg-1",
    row_cols_lg_2 = "row-cols-lg-2",
    row_cols_lg_3 = "row-cols-lg-3",
    row_cols_lg_4 = "row-cols-lg-4",
    row_cols_lg_5 = "row-cols-lg-5",
    row_cols_lg_6 = "row-cols-lg-6",
    col_lg_auto = "col-lg-auto",
    col_lg_1 = "col-lg-1",
    col_lg_2 = "col-lg-2",
    col_lg_3 = "col-lg-3",
    col_lg_4 = "col-lg-4",
    col_lg_5 = "col-lg-5",
    col_lg_6 = "col-lg-6",
    col_lg_7 = "col-lg-7",
    col_lg_8 = "col-lg-8",
    col_lg_9 = "col-lg-9",
    col_lg_10 = "col-lg-10",
    col_lg_11 = "col-lg-11",
    col_lg_12 = "col-lg-12",
    offset_lg_0 = "offset-lg-0",
    offset_lg_1 = "offset-lg-1",
    offset_lg_2 = "offset-lg-2",
    offset_lg_3 = "offset-lg-3",
    offset_lg_4 = "offset-lg-4",
    offset_lg_5 = "offset-lg-5",
    offset_lg_6 = "offset-lg-6",
    offset_lg_7 = "offset-lg-7",
    offset_lg_8 = "offset-lg-8",
    offset_lg_9 = "offset-lg-9",
    offset_lg_10 = "offset-lg-10",
    offset_lg_11 = "offset-lg-11",
    g_lg_0 = "g-lg-0",
    gx_lg_0 = "gx-lg-0",
    gy_lg_0 = "gy-lg-0",
    g_lg_1 = "g-lg-1",
    gx_lg_1 = "gx-lg-1",
    gy_lg_1 = "gy-lg-1",
    g_lg_2 = "g-lg-2",
    gx_lg_2 = "gx-lg-2",
    gy_lg_2 = "gy-lg-2",
    g_lg_3 = "g-lg-3",
    gx_lg_3 = "gx-lg-3",
    gy_lg_3 = "gy-lg-3",
    g_lg_4 = "g-lg-4",
    gx_lg_4 = "gx-lg-4",
    gy_lg_4 = "gy-lg-4",
    g_lg_5 = "g-lg-5",
    gx_lg_5 = "gx-lg-5",
    gy_lg_5 = "gy-lg-5",
    col_xl = "col-xl",
    row_cols_xl_auto = "row-cols-xl-auto",
    row_cols_xl_1 = "row-cols-xl-1",
    row_cols_xl_2 = "row-cols-xl-2",
    row_cols_xl_3 = "row-cols-xl-3",
    row_cols_xl_4 = "row-cols-xl-4",
    row_cols_xl_5 = "row-cols-xl-5",
    row_cols_xl_6 = "row-cols-xl-6",
    col_xl_auto = "col-xl-auto",
    col_xl_1 = "col-xl-1",
    col_xl_2 = "col-xl-2",
    col_xl_3 = "col-xl-3",
    col_xl_4 = "col-xl-4",
    col_xl_5 = "col-xl-5",
    col_xl_6 = "col-xl-6",
    col_xl_7 = "col-xl-7",
    col_xl_8 = "col-xl-8",
    col_xl_9 = "col-xl-9",
    col_xl_10 = "col-xl-10",
    col_xl_11 = "col-xl-11",
    col_xl_12 = "col-xl-12",
    offset_xl_0 = "offset-xl-0",
    offset_xl_1 = "offset-xl-1",
    offset_xl_2 = "offset-xl-2",
    offset_xl_3 = "offset-xl-3",
    offset_xl_4 = "offset-xl-4",
    offset_xl_5 = "offset-xl-5",
    offset_xl_6 = "offset-xl-6",
    offset_xl_7 = "offset-xl-7",
    offset_xl_8 = "offset-xl-8",
    offset_xl_9 = "offset-xl-9",
    offset_xl_10 = "offset-xl-10",
    offset_xl_11 = "offset-xl-11",
    g_xl_0 = "g-xl-0",
    gx_xl_0 = "gx-xl-0",
    gy_xl_0 = "gy-xl-0",
    g_xl_1 = "g-xl-1",
    gx_xl_1 = "gx-xl-1",
    gy_xl_1 = "gy-xl-1",
    g_xl_2 = "g-xl-2",
    gx_xl_2 = "gx-xl-2",
    gy_xl_2 = "gy-xl-2",
    g_xl_3 = "g-xl-3",
    gx_xl_3 = "gx-xl-3",
    gy_xl_3 = "gy-xl-3",
    g_xl_4 = "g-xl-4",
    gx_xl_4 = "gx-xl-4",
    gy_xl_4 = "gy-xl-4",
    g_xl_5 = "g-xl-5",
    gx_xl_5 = "gx-xl-5",
    gy_xl_5 = "gy-xl-5",
    col_xxl = "col-xxl",
    row_cols_xxl_auto = "row-cols-xxl-auto",
    row_cols_xxl_1 = "row-cols-xxl-1",
    row_cols_xxl_2 = "row-cols-xxl-2",
    row_cols_xxl_3 = "row-cols-xxl-3",
    row_cols_xxl_4 = "row-cols-xxl-4",
    row_cols_xxl_5 = "row-cols-xxl-5",
    row_cols_xxl_6 = "row-cols-xxl-6",
    col_xxl_auto = "col-xxl-auto",
    col_xxl_1 = "col-xxl-1",
    col_xxl_2 = "col-xxl-2",
    col_xxl_3 = "col-xxl-3",
    col_xxl_4 = "col-xxl-4",
    col_xxl_5 = "col-xxl-5",
    col_xxl_6 = "col-xxl-6",
    col_xxl_7 = "col-xxl-7",
    col_xxl_8 = "col-xxl-8",
    col_xxl_9 = "col-xxl-9",
    col_xxl_10 = "col-xxl-10",
    col_xxl_11 = "col-xxl-11",
    col_xxl_12 = "col-xxl-12",
    offset_xxl_0 = "offset-xxl-0",
    offset_xxl_1 = "offset-xxl-1",
    offset_xxl_2 = "offset-xxl-2",
    offset_xxl_3 = "offset-xxl-3",
    offset_xxl_4 = "offset-xxl-4",
    offset_xxl_5 = "offset-xxl-5",
    offset_xxl_6 = "offset-xxl-6",
    offset_xxl_7 = "offset-xxl-7",
    offset_xxl_8 = "offset-xxl-8",
    offset_xxl_9 = "offset-xxl-9",
    offset_xxl_10 = "offset-xxl-10",
    offset_xxl_11 = "offset-xxl-11",
    g_xxl_0 = "g-xxl-0",
    gx_xxl_0 = "gx-xxl-0",
    gy_xxl_0 = "gy-xxl-0",
    g_xxl_1 = "g-xxl-1",
    gx_xxl_1 = "gx-xxl-1",
    gy_xxl_1 = "gy-xxl-1",
    g_xxl_2 = "g-xxl-2",
    gx_xxl_2 = "gx-xxl-2",
    gy_xxl_2 = "gy-xxl-2",
    g_xxl_3 = "g-xxl-3",
    gx_xxl_3 = "gx-xxl-3",
    gy_xxl_3 = "gy-xxl-3",
    g_xxl_4 = "g-xxl-4",
    gx_xxl_4 = "gx-xxl-4",
    gy_xxl_4 = "gy-xxl-4",
    g_xxl_5 = "g-xxl-5",
    gx_xxl_5 = "gx-xxl-5",
    gy_xxl_5 = "gy-xxl-5",
    table = "table",
    table_group_divider = "table-group-divider",
    caption_top = "caption-top",
    table_sm = "table-sm",
    table_bordered = "table-bordered",
    table_borderless = "table-borderless",
    table_striped = "table-striped",
    table_striped_columns = "table-striped-columns",
    table_active = "table-active",
    table_hover = "table-hover",
    table_primary = "table-primary",
    table_secondary = "table-secondary",
    table_success = "table-success",
    table_info = "table-info",
    table_warning = "table-warning",
    table_danger = "table-danger",
    table_light = "table-light",
    table_dark = "table-dark",
    table_responsive = "table-responsive",
    table_responsive_sm = "table-responsive-sm",
    table_responsive_md = "table-responsive-md",
    table_responsive_lg = "table-responsive-lg",
    table_responsive_xl = "table-responsive-xl",
    table_responsive_xxl = "table-responsive-xxl",
    form_label = "form-label",
    col_form_label = "col-form-label",
    col_form_label_lg = "col-form-label-lg",
    col_form_label_sm = "col-form-label-sm",
    form_text = "form-text",
    form_control = "form-control",
    form_control_plaintext = "form-control-plaintext",
    form_control_lg = "form-control-lg",
    form_control_sm = "form-control-sm",
    form_control_color = "form-control-color",
    form_select = "form-select",
    w3 = "w3",
    org = "org",
    form_select_sm = "form-select-sm",
    form_select_lg = "form-select-lg",
    form_check = "form-check",
    form_check_input = "form-check-input",
    form_check_reverse = "form-check-reverse",
    form_check_label = "form-check-label",
    form_switch = "form-switch",
    form_check_inline = "form-check-inline",
    btn_check = "btn-check",
    btn = "btn",
    form_range = "form-range",
    form_floating = "form-floating",
    input_group = "input-group",
    input_group_text = "input-group-text",
    input_group_lg = "input-group-lg",
    input_group_sm = "input-group-sm",
    has_validation = "has-validation",
    dropdown_toggle = "dropdown-toggle",
    dropdown_menu = "dropdown-menu",
    valid_tooltip = "valid-tooltip",
    valid_feedback = "valid-feedback",
    invalid_tooltip = "invalid-tooltip",
    invalid_feedback = "invalid-feedback",
    is_valid = "is-valid",
    was_validated = "was-validated",
    is_invalid = "is-invalid",
    active = "active",
    show = "show",
    disabled = "disabled",
    btn_primary = "btn-primary",
    btn_secondary = "btn-secondary",
    btn_success = "btn-success",
    btn_info = "btn-info",
    btn_warning = "btn-warning",
    btn_danger = "btn-danger",
    btn_light = "btn-light",
    btn_dark = "btn-dark",
    btn_outline_primary = "btn-outline-primary",
    btn_outline_secondary = "btn-outline-secondary",
    btn_outline_success = "btn-outline-success",
    btn_outline_info = "btn-outline-info",
    btn_outline_warning = "btn-outline-warning",
    btn_outline_danger = "btn-outline-danger",
    btn_outline_light = "btn-outline-light",
    btn_outline_dark = "btn-outline-dark",
    btn_link = "btn-link",
    btn_group_lg = "btn-group-lg",
    btn_lg = "btn-lg",
    btn_group_sm = "btn-group-sm",
    btn_sm = "btn-sm",
    fade = "fade",
    collapse = "collapse",
    collapsing = "collapsing",
    collapse_horizontal = "collapse-horizontal",
    dropdown = "dropdown",
    dropdown_center = "dropdown-center",
    dropend = "dropend",
    dropstart = "dropstart",
    dropup = "dropup",
    dropup_center = "dropup-center",
    dropdown_menu_start = "dropdown-menu-start",
    dropdown_menu_end = "dropdown-menu-end",
    dropdown_menu_sm_start = "dropdown-menu-sm-start",
    dropdown_menu_sm_end = "dropdown-menu-sm-end",
    dropdown_menu_md_start = "dropdown-menu-md-start",
    dropdown_menu_md_end = "dropdown-menu-md-end",
    dropdown_menu_lg_start = "dropdown-menu-lg-start",
    dropdown_menu_lg_end = "dropdown-menu-lg-end",
    dropdown_menu_xl_start = "dropdown-menu-xl-start",
    dropdown_menu_xl_end = "dropdown-menu-xl-end",
    dropdown_menu_xxl_start = "dropdown-menu-xxl-start",
    dropdown_menu_xxl_end = "dropdown-menu-xxl-end",
    dropdown_divider = "dropdown-divider",
    dropdown_item = "dropdown-item",
    dropdown_header = "dropdown-header",
    dropdown_item_text = "dropdown-item-text",
    dropdown_menu_dark = "dropdown-menu-dark",
    btn_group = "btn-group",
    btn_group_vertical = "btn-group-vertical",
    btn_toolbar = "btn-toolbar",
    dropdown_toggle_split = "dropdown-toggle-split",
    nav = "nav",
    nav_link = "nav-link",
    nav_tabs = "nav-tabs",
    nav_item = "nav-item",
    nav_pills = "nav-pills",
    nav_fill = "nav-fill",
    nav_justified = "nav-justified",
    tab_content = "tab-content",
    tab_pane = "tab-pane",
    navbar = "navbar",
    navbar_brand = "navbar-brand",
    navbar_nav = "navbar-nav",
    navbar_text = "navbar-text",
    navbar_collapse = "navbar-collapse",
    navbar_toggler = "navbar-toggler",
    navbar_toggler_icon = "navbar-toggler-icon",
    navbar_nav_scroll = "navbar-nav-scroll",
    navbar_expand_sm = "navbar-expand-sm",
    offcanvas = "offcanvas",
    offcanvas_header = "offcanvas-header",
    offcanvas_body = "offcanvas-body",
    navbar_expand_md = "navbar-expand-md",
    navbar_expand_lg = "navbar-expand-lg",
    navbar_expand_xl = "navbar-expand-xl",
    navbar_expand_xxl = "navbar-expand-xxl",
    navbar_expand = "navbar-expand",
    navbar_dark = "navbar-dark",
    card = "card",
    list_group = "list-group",
    card_header = "card-header",
    card_footer = "card-footer",
    card_body = "card-body",
    card_title = "card-title",
    card_subtitle = "card-subtitle",
    card_text = "card-text",
    card_link = "card-link",
    card_header_tabs = "card-header-tabs",
    card_header_pills = "card-header-pills",
    card_img_overlay = "card-img-overlay",
    card_img = "card-img",
    card_img_bottom = "card-img-bottom",
    card_img_top = "card-img-top",
    card_group = "card-group",
    accordion = "accordion",
    accordion_button = "accordion-button",
    collapsed = "collapsed",
    accordion_header = "accordion-header",
    accordion_item = "accordion-item",
    accordion_collapse = "accordion-collapse",
    accordion_body = "accordion-body",
    accordion_flush = "accordion-flush",
    breadcrumb = "breadcrumb",
    breadcrumb_item = "breadcrumb-item",
    pagination = "pagination",
    page_link = "page-link",
    page_item = "page-item",
    pagination_lg = "pagination-lg",
    pagination_sm = "pagination-sm",
    badge = "badge",
    alert = "alert",
    alert_heading = "alert-heading",
    alert_link = "alert-link",
    alert_dismissible = "alert-dismissible",
    btn_close = "btn-close",
    alert_primary = "alert-primary",
    alert_secondary = "alert-secondary",
    alert_success = "alert-success",
    alert_info = "alert-info",
    alert_warning = "alert-warning",
    alert_danger = "alert-danger",
    alert_light = "alert-light",
    alert_dark = "alert-dark",
    progress = "progress",
    progress_bar = "progress-bar",
    progress_bar_striped = "progress-bar-striped",
    progress_bar_animated = "progress-bar-animated",
    list_group_numbered = "list-group-numbered",
    list_group_item = "list-group-item",
    list_group_item_action = "list-group-item-action",
    list_group_horizontal = "list-group-horizontal",
    list_group_horizontal_sm = "list-group-horizontal-sm",
    list_group_horizontal_md = "list-group-horizontal-md",
    list_group_horizontal_lg = "list-group-horizontal-lg",
    list_group_horizontal_xl = "list-group-horizontal-xl",
    list_group_horizontal_xxl = "list-group-horizontal-xxl",
    list_group_flush = "list-group-flush",
    list_group_item_primary = "list-group-item-primary",
    list_group_item_secondary = "list-group-item-secondary",
    list_group_item_success = "list-group-item-success",
    list_group_item_info = "list-group-item-info",
    list_group_item_warning = "list-group-item-warning",
    list_group_item_danger = "list-group-item-danger",
    list_group_item_light = "list-group-item-light",
    list_group_item_dark = "list-group-item-dark",
    btn_close_white = "btn-close-white",
    toast = "toast",
    showing = "showing",
    toast_container = "toast-container",
    toast_header = "toast-header",
    toast_body = "toast-body",
    modal = "modal",
    modal_dialog = "modal-dialog",
    modal_static = "modal-static",
    modal_dialog_scrollable = "modal-dialog-scrollable",
    modal_content = "modal-content",
    modal_body = "modal-body",
    modal_dialog_centered = "modal-dialog-centered",
    modal_backdrop = "modal-backdrop",
    modal_header = "modal-header",
    modal_title = "modal-title",
    modal_footer = "modal-footer",
    modal_sm = "modal-sm",
    modal_lg = "modal-lg",
    modal_xl = "modal-xl",
    modal_fullscreen = "modal-fullscreen",
    modal_fullscreen_sm_down = "modal-fullscreen-sm-down",
    modal_fullscreen_md_down = "modal-fullscreen-md-down",
    modal_fullscreen_lg_down = "modal-fullscreen-lg-down",
    modal_fullscreen_xl_down = "modal-fullscreen-xl-down",
    modal_fullscreen_xxl_down = "modal-fullscreen-xxl-down",
    tooltip = "tooltip",
    tooltip_arrow = "tooltip-arrow",
    bs_tooltip_auto = "bs-tooltip-auto",
    bs_tooltip_top = "bs-tooltip-top",
    bs_tooltip_end = "bs-tooltip-end",
    bs_tooltip_bottom = "bs-tooltip-bottom",
    bs_tooltip_start = "bs-tooltip-start",
    tooltip_inner = "tooltip-inner",
    popover = "popover",
    popover_arrow = "popover-arrow",
    bs_popover_auto = "bs-popover-auto",
    bs_popover_top = "bs-popover-top",
    bs_popover_end = "bs-popover-end",
    bs_popover_bottom = "bs-popover-bottom",
    popover_header = "popover-header",
    bs_popover_start = "bs-popover-start",
    popover_body = "popover-body",
    carousel = "carousel",
    pointer_event = "pointer-event",
    carousel_inner = "carousel-inner",
    carousel_item = "carousel-item",
    carousel_item_next = "carousel-item-next",
    carousel_item_prev = "carousel-item-prev",
    carousel_item_end = "carousel-item-end",
    carousel_item_start = "carousel-item-start",
    carousel_fade = "carousel-fade",
    carousel_control_next = "carousel-control-next",
    carousel_control_prev = "carousel-control-prev",
    carousel_control_next_icon = "carousel-control-next-icon",
    carousel_control_prev_icon = "carousel-control-prev-icon",
    carousel_indicators = "carousel-indicators",
    carousel_caption = "carousel-caption",
    carousel_dark = "carousel-dark",
    spinner_border = "spinner-border",
    spinner_grow = "spinner-grow",
    spinner_border_sm = "spinner-border-sm",
    spinner_grow_sm = "spinner-grow-sm",
    offcanvas_lg = "offcanvas-lg",
    offcanvas_md = "offcanvas-md",
    offcanvas_sm = "offcanvas-sm",
    offcanvas_xl = "offcanvas-xl",
    offcanvas_xxl = "offcanvas-xxl",
    offcanvas_start = "offcanvas-start",
    offcanvas_end = "offcanvas-end",
    offcanvas_top = "offcanvas-top",
    offcanvas_bottom = "offcanvas-bottom",
    hiding = "hiding",
    offcanvas_backdrop = "offcanvas-backdrop",
    offcanvas_title = "offcanvas-title",
    placeholder = "placeholder",
    placeholder_xs = "placeholder-xs",
    placeholder_sm = "placeholder-sm",
    placeholder_lg = "placeholder-lg",
    placeholder_glow = "placeholder-glow",
    placeholder_wave = "placeholder-wave",
    clearfix = "clearfix",
    text_bg_primary = "text-bg-primary",
    text_bg_secondary = "text-bg-secondary",
    text_bg_success = "text-bg-success",
    text_bg_info = "text-bg-info",
    text_bg_warning = "text-bg-warning",
    text_bg_danger = "text-bg-danger",
    text_bg_light = "text-bg-light",
    text_bg_dark = "text-bg-dark",
    link_primary = "link-primary",
    link_secondary = "link-secondary",
    link_success = "link-success",
    link_info = "link-info",
    link_warning = "link-warning",
    link_danger = "link-danger",
    link_light = "link-light",
    link_dark = "link-dark",
    ratio = "ratio",
    ratio_1x1 = "ratio-1x1",
    ratio_4x3 = "ratio-4x3",
    ratio_16x9 = "ratio-16x9",
    ratio_21x9 = "ratio-21x9",
    fixed_top = "fixed-top",
    fixed_bottom = "fixed-bottom",
    sticky_top = "sticky-top",
    sticky_bottom = "sticky-bottom",
    sticky_sm_top = "sticky-sm-top",
    sticky_sm_bottom = "sticky-sm-bottom",
    sticky_md_top = "sticky-md-top",
    sticky_md_bottom = "sticky-md-bottom",
    sticky_lg_top = "sticky-lg-top",
    sticky_lg_bottom = "sticky-lg-bottom",
    sticky_xl_top = "sticky-xl-top",
    sticky_xl_bottom = "sticky-xl-bottom",
    sticky_xxl_top = "sticky-xxl-top",
    sticky_xxl_bottom = "sticky-xxl-bottom",
    hstack = "hstack",
    vstack = "vstack",
    visually_hidden = "visually-hidden",
    visually_hidden_focusable = "visually-hidden-focusable",
    stretched_link = "stretched-link",
    text_truncate = "text-truncate",
    vr = "vr",
    align_baseline = "align-baseline",
    align_top = "align-top",
    align_middle = "align-middle",
    align_bottom = "align-bottom",
    align_text_bottom = "align-text-bottom",
    align_text_top = "align-text-top",
    float_start = "float-start",
    float_end = "float-end",
    float_none = "float-none",
    opacity_0 = "opacity-0",
    opacity_25 = "opacity-25",
    opacity_50 = "opacity-50",
    opacity_75 = "opacity-75",
    opacity_100 = "opacity-100",
    overflow_auto = "overflow-auto",
    overflow_hidden = "overflow-hidden",
    overflow_visible = "overflow-visible",
    overflow_scroll = "overflow-scroll",
    d_inline = "d-inline",
    d_inline_block = "d-inline-block",
    d_block = "d-block",
    d_grid = "d-grid",
    d_table = "d-table",
    d_table_row = "d-table-row",
    d_table_cell = "d-table-cell",
    d_flex = "d-flex",
    d_inline_flex = "d-inline-flex",
    d_none = "d-none",
    shadow = "shadow",
    shadow_sm = "shadow-sm",
    shadow_lg = "shadow-lg",
    shadow_none = "shadow-none",
    position_static = "position-static",
    position_relative = "position-relative",
    position_absolute = "position-absolute",
    position_fixed = "position-fixed",
    position_sticky = "position-sticky",
    top_0 = "top-0",
    top_50 = "top-50",
    top_100 = "top-100",
    bottom_0 = "bottom-0",
    bottom_50 = "bottom-50",
    bottom_100 = "bottom-100",
    start_0 = "start-0",
    start_50 = "start-50",
    start_100 = "start-100",
    end_0 = "end-0",
    end_50 = "end-50",
    end_100 = "end-100",
    translate_middle = "translate-middle",
    translate_middle_x = "translate-middle-x",
    translate_middle_y = "translate-middle-y",
    border = "border",
    border_0 = "border-0",
    border_top = "border-top",
    border_top_0 = "border-top-0",
    border_end = "border-end",
    border_end_0 = "border-end-0",
    border_bottom = "border-bottom",
    border_bottom_0 = "border-bottom-0",
    border_start = "border-start",
    border_start_0 = "border-start-0",
    border_primary = "border-primary",
    border_secondary = "border-secondary",
    border_success = "border-success",
    border_info = "border-info",
    border_warning = "border-warning",
    border_danger = "border-danger",
    border_light = "border-light",
    border_dark = "border-dark",
    border_white = "border-white",
    border_1 = "border-1",
    border_2 = "border-2",
    border_3 = "border-3",
    border_4 = "border-4",
    border_5 = "border-5",
    border_opacity_10 = "border-opacity-10",
    border_opacity_25 = "border-opacity-25",
    border_opacity_50 = "border-opacity-50",
    border_opacity_75 = "border-opacity-75",
    border_opacity_100 = "border-opacity-100",
    w_25 = "w-25",
    w_50 = "w-50",
    w_75 = "w-75",
    w_100 = "w-100",
    w_auto = "w-auto",
    mw_100 = "mw-100",
    vw_100 = "vw-100",
    min_vw_100 = "min-vw-100",
    h_25 = "h-25",
    h_50 = "h-50",
    h_75 = "h-75",
    h_100 = "h-100",
    h_auto = "h-auto",
    mh_100 = "mh-100",
    vh_100 = "vh-100",
    min_vh_100 = "min-vh-100",
    flex_fill = "flex-fill",
    flex_row = "flex-row",
    flex_column = "flex-column",
    flex_row_reverse = "flex-row-reverse",
    flex_column_reverse = "flex-column-reverse",
    flex_grow_0 = "flex-grow-0",
    flex_grow_1 = "flex-grow-1",
    flex_shrink_0 = "flex-shrink-0",
    flex_shrink_1 = "flex-shrink-1",
    flex_wrap = "flex-wrap",
    flex_nowrap = "flex-nowrap",
    flex_wrap_reverse = "flex-wrap-reverse",
    justify_content_start = "justify-content-start",
    justify_content_end = "justify-content-end",
    justify_content_center = "justify-content-center",
    justify_content_between = "justify-content-between",
    justify_content_around = "justify-content-around",
    justify_content_evenly = "justify-content-evenly",
    align_items_start = "align-items-start",
    align_items_end = "align-items-end",
    align_items_center = "align-items-center",
    align_items_baseline = "align-items-baseline",
    align_items_stretch = "align-items-stretch",
    align_content_start = "align-content-start",
    align_content_end = "align-content-end",
    align_content_center = "align-content-center",
    align_content_between = "align-content-between",
    align_content_around = "align-content-around",
    align_content_stretch = "align-content-stretch",
    align_self_auto = "align-self-auto",
    align_self_start = "align-self-start",
    align_self_end = "align-self-end",
    align_self_center = "align-self-center",
    align_self_baseline = "align-self-baseline",
    align_self_stretch = "align-self-stretch",
    order_first = "order-first",
    order_0 = "order-0",
    order_1 = "order-1",
    order_2 = "order-2",
    order_3 = "order-3",
    order_4 = "order-4",
    order_5 = "order-5",
    order_last = "order-last",
    m_0 = "m-0",
    m_1 = "m-1",
    m_2 = "m-2",
    m_3 = "m-3",
    m_4 = "m-4",
    m_5 = "m-5",
    m_auto = "m-auto",
    mx_0 = "mx-0",
    mx_1 = "mx-1",
    mx_2 = "mx-2",
    mx_3 = "mx-3",
    mx_4 = "mx-4",
    mx_5 = "mx-5",
    mx_auto = "mx-auto",
    my_0 = "my-0",
    my_1 = "my-1",
    my_2 = "my-2",
    my_3 = "my-3",
    my_4 = "my-4",
    my_5 = "my-5",
    my_auto = "my-auto",
    mt_0 = "mt-0",
    mt_1 = "mt-1",
    mt_2 = "mt-2",
    mt_3 = "mt-3",
    mt_4 = "mt-4",
    mt_5 = "mt-5",
    mt_auto = "mt-auto",
    me_0 = "me-0",
    me_1 = "me-1",
    me_2 = "me-2",
    me_3 = "me-3",
    me_4 = "me-4",
    me_5 = "me-5",
    me_auto = "me-auto",
    mb_0 = "mb-0",
    mb_1 = "mb-1",
    mb_2 = "mb-2",
    mb_3 = "mb-3",
    mb_4 = "mb-4",
    mb_5 = "mb-5",
    mb_auto = "mb-auto",
    ms_0 = "ms-0",
    ms_1 = "ms-1",
    ms_2 = "ms-2",
    ms_3 = "ms-3",
    ms_4 = "ms-4",
    ms_5 = "ms-5",
    ms_auto = "ms-auto",
    p_0 = "p-0",
    p_1 = "p-1",
    p_2 = "p-2",
    p_3 = "p-3",
    p_4 = "p-4",
    p_5 = "p-5",
    px_0 = "px-0",
    px_1 = "px-1",
    px_2 = "px-2",
    px_3 = "px-3",
    px_4 = "px-4",
    px_5 = "px-5",
    py_0 = "py-0",
    py_1 = "py-1",
    py_2 = "py-2",
    py_3 = "py-3",
    py_4 = "py-4",
    py_5 = "py-5",
    pt_0 = "pt-0",
    pt_1 = "pt-1",
    pt_2 = "pt-2",
    pt_3 = "pt-3",
    pt_4 = "pt-4",
    pt_5 = "pt-5",
    pe_0 = "pe-0",
    pe_1 = "pe-1",
    pe_2 = "pe-2",
    pe_3 = "pe-3",
    pe_4 = "pe-4",
    pe_5 = "pe-5",
    pb_0 = "pb-0",
    pb_1 = "pb-1",
    pb_2 = "pb-2",
    pb_3 = "pb-3",
    pb_4 = "pb-4",
    pb_5 = "pb-5",
    ps_0 = "ps-0",
    ps_1 = "ps-1",
    ps_2 = "ps-2",
    ps_3 = "ps-3",
    ps_4 = "ps-4",
    ps_5 = "ps-5",
    gap_0 = "gap-0",
    gap_1 = "gap-1",
    gap_2 = "gap-2",
    gap_3 = "gap-3",
    gap_4 = "gap-4",
    gap_5 = "gap-5",
    font_monospace = "font-monospace",
    fs_1 = "fs-1",
    fs_2 = "fs-2",
    fs_3 = "fs-3",
    fs_4 = "fs-4",
    fs_5 = "fs-5",
    fs_6 = "fs-6",
    fst_italic = "fst-italic",
    fst_normal = "fst-normal",
    fw_light = "fw-light",
    fw_lighter = "fw-lighter",
    fw_normal = "fw-normal",
    fw_bold = "fw-bold",
    fw_semibold = "fw-semibold",
    fw_bolder = "fw-bolder",
    lh_1 = "lh-1",
    lh_sm = "lh-sm",
    lh_base = "lh-base",
    lh_lg = "lh-lg",
    text_start = "text-start",
    text_end = "text-end",
    text_center = "text-center",
    text_decoration_none = "text-decoration-none",
    text_decoration_underline = "text-decoration-underline",
    text_decoration_line_through = "text-decoration-line-through",
    text_lowercase = "text-lowercase",
    text_uppercase = "text-uppercase",
    text_capitalize = "text-capitalize",
    text_wrap = "text-wrap",
    text_nowrap = "text-nowrap",
    text_break = "text-break",
    text_primary = "text-primary",
    text_secondary = "text-secondary",
    text_success = "text-success",
    text_info = "text-info",
    text_warning = "text-warning",
    text_danger = "text-danger",
    text_light = "text-light",
    text_dark = "text-dark",
    text_black = "text-black",
    text_white = "text-white",
    text_body = "text-body",
    text_muted = "text-muted",
    text_black_50 = "text-black-50",
    text_white_50 = "text-white-50",
    text_reset = "text-reset",
    text_opacity_25 = "text-opacity-25",
    text_opacity_50 = "text-opacity-50",
    text_opacity_75 = "text-opacity-75",
    text_opacity_100 = "text-opacity-100",
    bg_primary = "bg-primary",
    bg_secondary = "bg-secondary",
    bg_success = "bg-success",
    bg_info = "bg-info",
    bg_warning = "bg-warning",
    bg_danger = "bg-danger",
    bg_light = "bg-light",
    bg_dark = "bg-dark",
    bg_black = "bg-black",
    bg_white = "bg-white",
    bg_body = "bg-body",
    bg_transparent = "bg-transparent",
    bg_opacity_10 = "bg-opacity-10",
    bg_opacity_25 = "bg-opacity-25",
    bg_opacity_50 = "bg-opacity-50",
    bg_opacity_75 = "bg-opacity-75",
    bg_opacity_100 = "bg-opacity-100",
    bg_gradient = "bg-gradient",
    user_select_all = "user-select-all",
    user_select_auto = "user-select-auto",
    user_select_none = "user-select-none",
    pe_none = "pe-none",
    pe_auto = "pe-auto",
    rounded = "rounded",
    rounded_0 = "rounded-0",
    rounded_1 = "rounded-1",
    rounded_2 = "rounded-2",
    rounded_3 = "rounded-3",
    rounded_4 = "rounded-4",
    rounded_5 = "rounded-5",
    rounded_circle = "rounded-circle",
    rounded_pill = "rounded-pill",
    rounded_top = "rounded-top",
    rounded_end = "rounded-end",
    rounded_bottom = "rounded-bottom",
    rounded_start = "rounded-start",
    visible = "visible",
    invisible = "invisible",
    float_sm_start = "float-sm-start",
    float_sm_end = "float-sm-end",
    float_sm_none = "float-sm-none",
    d_sm_inline = "d-sm-inline",
    d_sm_inline_block = "d-sm-inline-block",
    d_sm_block = "d-sm-block",
    d_sm_grid = "d-sm-grid",
    d_sm_table = "d-sm-table",
    d_sm_table_row = "d-sm-table-row",
    d_sm_table_cell = "d-sm-table-cell",
    d_sm_flex = "d-sm-flex",
    d_sm_inline_flex = "d-sm-inline-flex",
    d_sm_none = "d-sm-none",
    flex_sm_fill = "flex-sm-fill",
    flex_sm_row = "flex-sm-row",
    flex_sm_column = "flex-sm-column",
    flex_sm_row_reverse = "flex-sm-row-reverse",
    flex_sm_column_reverse = "flex-sm-column-reverse",
    flex_sm_grow_0 = "flex-sm-grow-0",
    flex_sm_grow_1 = "flex-sm-grow-1",
    flex_sm_shrink_0 = "flex-sm-shrink-0",
    flex_sm_shrink_1 = "flex-sm-shrink-1",
    flex_sm_wrap = "flex-sm-wrap",
    flex_sm_nowrap = "flex-sm-nowrap",
    flex_sm_wrap_reverse = "flex-sm-wrap-reverse",
    justify_content_sm_start = "justify-content-sm-start",
    justify_content_sm_end = "justify-content-sm-end",
    justify_content_sm_center = "justify-content-sm-center",
    justify_content_sm_between = "justify-content-sm-between",
    justify_content_sm_around = "justify-content-sm-around",
    justify_content_sm_evenly = "justify-content-sm-evenly",
    align_items_sm_start = "align-items-sm-start",
    align_items_sm_end = "align-items-sm-end",
    align_items_sm_center = "align-items-sm-center",
    align_items_sm_baseline = "align-items-sm-baseline",
    align_items_sm_stretch = "align-items-sm-stretch",
    align_content_sm_start = "align-content-sm-start",
    align_content_sm_end = "align-content-sm-end",
    align_content_sm_center = "align-content-sm-center",
    align_content_sm_between = "align-content-sm-between",
    align_content_sm_around = "align-content-sm-around",
    align_content_sm_stretch = "align-content-sm-stretch",
    align_self_sm_auto = "align-self-sm-auto",
    align_self_sm_start = "align-self-sm-start",
    align_self_sm_end = "align-self-sm-end",
    align_self_sm_center = "align-self-sm-center",
    align_self_sm_baseline = "align-self-sm-baseline",
    align_self_sm_stretch = "align-self-sm-stretch",
    order_sm_first = "order-sm-first",
    order_sm_0 = "order-sm-0",
    order_sm_1 = "order-sm-1",
    order_sm_2 = "order-sm-2",
    order_sm_3 = "order-sm-3",
    order_sm_4 = "order-sm-4",
    order_sm_5 = "order-sm-5",
    order_sm_last = "order-sm-last",
    m_sm_0 = "m-sm-0",
    m_sm_1 = "m-sm-1",
    m_sm_2 = "m-sm-2",
    m_sm_3 = "m-sm-3",
    m_sm_4 = "m-sm-4",
    m_sm_5 = "m-sm-5",
    m_sm_auto = "m-sm-auto",
    mx_sm_0 = "mx-sm-0",
    mx_sm_1 = "mx-sm-1",
    mx_sm_2 = "mx-sm-2",
    mx_sm_3 = "mx-sm-3",
    mx_sm_4 = "mx-sm-4",
    mx_sm_5 = "mx-sm-5",
    mx_sm_auto = "mx-sm-auto",
    my_sm_0 = "my-sm-0",
    my_sm_1 = "my-sm-1",
    my_sm_2 = "my-sm-2",
    my_sm_3 = "my-sm-3",
    my_sm_4 = "my-sm-4",
    my_sm_5 = "my-sm-5",
    my_sm_auto = "my-sm-auto",
    mt_sm_0 = "mt-sm-0",
    mt_sm_1 = "mt-sm-1",
    mt_sm_2 = "mt-sm-2",
    mt_sm_3 = "mt-sm-3",
    mt_sm_4 = "mt-sm-4",
    mt_sm_5 = "mt-sm-5",
    mt_sm_auto = "mt-sm-auto",
    me_sm_0 = "me-sm-0",
    me_sm_1 = "me-sm-1",
    me_sm_2 = "me-sm-2",
    me_sm_3 = "me-sm-3",
    me_sm_4 = "me-sm-4",
    me_sm_5 = "me-sm-5",
    me_sm_auto = "me-sm-auto",
    mb_sm_0 = "mb-sm-0",
    mb_sm_1 = "mb-sm-1",
    mb_sm_2 = "mb-sm-2",
    mb_sm_3 = "mb-sm-3",
    mb_sm_4 = "mb-sm-4",
    mb_sm_5 = "mb-sm-5",
    mb_sm_auto = "mb-sm-auto",
    ms_sm_0 = "ms-sm-0",
    ms_sm_1 = "ms-sm-1",
    ms_sm_2 = "ms-sm-2",
    ms_sm_3 = "ms-sm-3",
    ms_sm_4 = "ms-sm-4",
    ms_sm_5 = "ms-sm-5",
    ms_sm_auto = "ms-sm-auto",
    p_sm_0 = "p-sm-0",
    p_sm_1 = "p-sm-1",
    p_sm_2 = "p-sm-2",
    p_sm_3 = "p-sm-3",
    p_sm_4 = "p-sm-4",
    p_sm_5 = "p-sm-5",
    px_sm_0 = "px-sm-0",
    px_sm_1 = "px-sm-1",
    px_sm_2 = "px-sm-2",
    px_sm_3 = "px-sm-3",
    px_sm_4 = "px-sm-4",
    px_sm_5 = "px-sm-5",
    py_sm_0 = "py-sm-0",
    py_sm_1 = "py-sm-1",
    py_sm_2 = "py-sm-2",
    py_sm_3 = "py-sm-3",
    py_sm_4 = "py-sm-4",
    py_sm_5 = "py-sm-5",
    pt_sm_0 = "pt-sm-0",
    pt_sm_1 = "pt-sm-1",
    pt_sm_2 = "pt-sm-2",
    pt_sm_3 = "pt-sm-3",
    pt_sm_4 = "pt-sm-4",
    pt_sm_5 = "pt-sm-5",
    pe_sm_0 = "pe-sm-0",
    pe_sm_1 = "pe-sm-1",
    pe_sm_2 = "pe-sm-2",
    pe_sm_3 = "pe-sm-3",
    pe_sm_4 = "pe-sm-4",
    pe_sm_5 = "pe-sm-5",
    pb_sm_0 = "pb-sm-0",
    pb_sm_1 = "pb-sm-1",
    pb_sm_2 = "pb-sm-2",
    pb_sm_3 = "pb-sm-3",
    pb_sm_4 = "pb-sm-4",
    pb_sm_5 = "pb-sm-5",
    ps_sm_0 = "ps-sm-0",
    ps_sm_1 = "ps-sm-1",
    ps_sm_2 = "ps-sm-2",
    ps_sm_3 = "ps-sm-3",
    ps_sm_4 = "ps-sm-4",
    ps_sm_5 = "ps-sm-5",
    gap_sm_0 = "gap-sm-0",
    gap_sm_1 = "gap-sm-1",
    gap_sm_2 = "gap-sm-2",
    gap_sm_3 = "gap-sm-3",
    gap_sm_4 = "gap-sm-4",
    gap_sm_5 = "gap-sm-5",
    text_sm_start = "text-sm-start",
    text_sm_end = "text-sm-end",
    text_sm_center = "text-sm-center",
    float_md_start = "float-md-start",
    float_md_end = "float-md-end",
    float_md_none = "float-md-none",
    d_md_inline = "d-md-inline",
    d_md_inline_block = "d-md-inline-block",
    d_md_block = "d-md-block",
    d_md_grid = "d-md-grid",
    d_md_table = "d-md-table",
    d_md_table_row = "d-md-table-row",
    d_md_table_cell = "d-md-table-cell",
    d_md_flex = "d-md-flex",
    d_md_inline_flex = "d-md-inline-flex",
    d_md_none = "d-md-none",
    flex_md_fill = "flex-md-fill",
    flex_md_row = "flex-md-row",
    flex_md_column = "flex-md-column",
    flex_md_row_reverse = "flex-md-row-reverse",
    flex_md_column_reverse = "flex-md-column-reverse",
    flex_md_grow_0 = "flex-md-grow-0",
    flex_md_grow_1 = "flex-md-grow-1",
    flex_md_shrink_0 = "flex-md-shrink-0",
    flex_md_shrink_1 = "flex-md-shrink-1",
    flex_md_wrap = "flex-md-wrap",
    flex_md_nowrap = "flex-md-nowrap",
    flex_md_wrap_reverse = "flex-md-wrap-reverse",
    justify_content_md_start = "justify-content-md-start",
    justify_content_md_end = "justify-content-md-end",
    justify_content_md_center = "justify-content-md-center",
    justify_content_md_between = "justify-content-md-between",
    justify_content_md_around = "justify-content-md-around",
    justify_content_md_evenly = "justify-content-md-evenly",
    align_items_md_start = "align-items-md-start",
    align_items_md_end = "align-items-md-end",
    align_items_md_center = "align-items-md-center",
    align_items_md_baseline = "align-items-md-baseline",
    align_items_md_stretch = "align-items-md-stretch",
    align_content_md_start = "align-content-md-start",
    align_content_md_end = "align-content-md-end",
    align_content_md_center = "align-content-md-center",
    align_content_md_between = "align-content-md-between",
    align_content_md_around = "align-content-md-around",
    align_content_md_stretch = "align-content-md-stretch",
    align_self_md_auto = "align-self-md-auto",
    align_self_md_start = "align-self-md-start",
    align_self_md_end = "align-self-md-end",
    align_self_md_center = "align-self-md-center",
    align_self_md_baseline = "align-self-md-baseline",
    align_self_md_stretch = "align-self-md-stretch",
    order_md_first = "order-md-first",
    order_md_0 = "order-md-0",
    order_md_1 = "order-md-1",
    order_md_2 = "order-md-2",
    order_md_3 = "order-md-3",
    order_md_4 = "order-md-4",
    order_md_5 = "order-md-5",
    order_md_last = "order-md-last",
    m_md_0 = "m-md-0",
    m_md_1 = "m-md-1",
    m_md_2 = "m-md-2",
    m_md_3 = "m-md-3",
    m_md_4 = "m-md-4",
    m_md_5 = "m-md-5",
    m_md_auto = "m-md-auto",
    mx_md_0 = "mx-md-0",
    mx_md_1 = "mx-md-1",
    mx_md_2 = "mx-md-2",
    mx_md_3 = "mx-md-3",
    mx_md_4 = "mx-md-4",
    mx_md_5 = "mx-md-5",
    mx_md_auto = "mx-md-auto",
    my_md_0 = "my-md-0",
    my_md_1 = "my-md-1",
    my_md_2 = "my-md-2",
    my_md_3 = "my-md-3",
    my_md_4 = "my-md-4",
    my_md_5 = "my-md-5",
    my_md_auto = "my-md-auto",
    mt_md_0 = "mt-md-0",
    mt_md_1 = "mt-md-1",
    mt_md_2 = "mt-md-2",
    mt_md_3 = "mt-md-3",
    mt_md_4 = "mt-md-4",
    mt_md_5 = "mt-md-5",
    mt_md_auto = "mt-md-auto",
    me_md_0 = "me-md-0",
    me_md_1 = "me-md-1",
    me_md_2 = "me-md-2",
    me_md_3 = "me-md-3",
    me_md_4 = "me-md-4",
    me_md_5 = "me-md-5",
    me_md_auto = "me-md-auto",
    mb_md_0 = "mb-md-0",
    mb_md_1 = "mb-md-1",
    mb_md_2 = "mb-md-2",
    mb_md_3 = "mb-md-3",
    mb_md_4 = "mb-md-4",
    mb_md_5 = "mb-md-5",
    mb_md_auto = "mb-md-auto",
    ms_md_0 = "ms-md-0",
    ms_md_1 = "ms-md-1",
    ms_md_2 = "ms-md-2",
    ms_md_3 = "ms-md-3",
    ms_md_4 = "ms-md-4",
    ms_md_5 = "ms-md-5",
    ms_md_auto = "ms-md-auto",
    p_md_0 = "p-md-0",
    p_md_1 = "p-md-1",
    p_md_2 = "p-md-2",
    p_md_3 = "p-md-3",
    p_md_4 = "p-md-4",
    p_md_5 = "p-md-5",
    px_md_0 = "px-md-0",
    px_md_1 = "px-md-1",
    px_md_2 = "px-md-2",
    px_md_3 = "px-md-3",
    px_md_4 = "px-md-4",
    px_md_5 = "px-md-5",
    py_md_0 = "py-md-0",
    py_md_1 = "py-md-1",
    py_md_2 = "py-md-2",
    py_md_3 = "py-md-3",
    py_md_4 = "py-md-4",
    py_md_5 = "py-md-5",
    pt_md_0 = "pt-md-0",
    pt_md_1 = "pt-md-1",
    pt_md_2 = "pt-md-2",
    pt_md_3 = "pt-md-3",
    pt_md_4 = "pt-md-4",
    pt_md_5 = "pt-md-5",
    pe_md_0 = "pe-md-0",
    pe_md_1 = "pe-md-1",
    pe_md_2 = "pe-md-2",
    pe_md_3 = "pe-md-3",
    pe_md_4 = "pe-md-4",
    pe_md_5 = "pe-md-5",
    pb_md_0 = "pb-md-0",
    pb_md_1 = "pb-md-1",
    pb_md_2 = "pb-md-2",
    pb_md_3 = "pb-md-3",
    pb_md_4 = "pb-md-4",
    pb_md_5 = "pb-md-5",
    ps_md_0 = "ps-md-0",
    ps_md_1 = "ps-md-1",
    ps_md_2 = "ps-md-2",
    ps_md_3 = "ps-md-3",
    ps_md_4 = "ps-md-4",
    ps_md_5 = "ps-md-5",
    gap_md_0 = "gap-md-0",
    gap_md_1 = "gap-md-1",
    gap_md_2 = "gap-md-2",
    gap_md_3 = "gap-md-3",
    gap_md_4 = "gap-md-4",
    gap_md_5 = "gap-md-5",
    text_md_start = "text-md-start",
    text_md_end = "text-md-end",
    text_md_center = "text-md-center",
    float_lg_start = "float-lg-start",
    float_lg_end = "float-lg-end",
    float_lg_none = "float-lg-none",
    d_lg_inline = "d-lg-inline",
    d_lg_inline_block = "d-lg-inline-block",
    d_lg_block = "d-lg-block",
    d_lg_grid = "d-lg-grid",
    d_lg_table = "d-lg-table",
    d_lg_table_row = "d-lg-table-row",
    d_lg_table_cell = "d-lg-table-cell",
    d_lg_flex = "d-lg-flex",
    d_lg_inline_flex = "d-lg-inline-flex",
    d_lg_none = "d-lg-none",
    flex_lg_fill = "flex-lg-fill",
    flex_lg_row = "flex-lg-row",
    flex_lg_column = "flex-lg-column",
    flex_lg_row_reverse = "flex-lg-row-reverse",
    flex_lg_column_reverse = "flex-lg-column-reverse",
    flex_lg_grow_0 = "flex-lg-grow-0",
    flex_lg_grow_1 = "flex-lg-grow-1",
    flex_lg_shrink_0 = "flex-lg-shrink-0",
    flex_lg_shrink_1 = "flex-lg-shrink-1",
    flex_lg_wrap = "flex-lg-wrap",
    flex_lg_nowrap = "flex-lg-nowrap",
    flex_lg_wrap_reverse = "flex-lg-wrap-reverse",
    justify_content_lg_start = "justify-content-lg-start",
    justify_content_lg_end = "justify-content-lg-end",
    justify_content_lg_center = "justify-content-lg-center",
    justify_content_lg_between = "justify-content-lg-between",
    justify_content_lg_around = "justify-content-lg-around",
    justify_content_lg_evenly = "justify-content-lg-evenly",
    align_items_lg_start = "align-items-lg-start",
    align_items_lg_end = "align-items-lg-end",
    align_items_lg_center = "align-items-lg-center",
    align_items_lg_baseline = "align-items-lg-baseline",
    align_items_lg_stretch = "align-items-lg-stretch",
    align_content_lg_start = "align-content-lg-start",
    align_content_lg_end = "align-content-lg-end",
    align_content_lg_center = "align-content-lg-center",
    align_content_lg_between = "align-content-lg-between",
    align_content_lg_around = "align-content-lg-around",
    align_content_lg_stretch = "align-content-lg-stretch",
    align_self_lg_auto = "align-self-lg-auto",
    align_self_lg_start = "align-self-lg-start",
    align_self_lg_end = "align-self-lg-end",
    align_self_lg_center = "align-self-lg-center",
    align_self_lg_baseline = "align-self-lg-baseline",
    align_self_lg_stretch = "align-self-lg-stretch",
    order_lg_first = "order-lg-first",
    order_lg_0 = "order-lg-0",
    order_lg_1 = "order-lg-1",
    order_lg_2 = "order-lg-2",
    order_lg_3 = "order-lg-3",
    order_lg_4 = "order-lg-4",
    order_lg_5 = "order-lg-5",
    order_lg_last = "order-lg-last",
    m_lg_0 = "m-lg-0",
    m_lg_1 = "m-lg-1",
    m_lg_2 = "m-lg-2",
    m_lg_3 = "m-lg-3",
    m_lg_4 = "m-lg-4",
    m_lg_5 = "m-lg-5",
    m_lg_auto = "m-lg-auto",
    mx_lg_0 = "mx-lg-0",
    mx_lg_1 = "mx-lg-1",
    mx_lg_2 = "mx-lg-2",
    mx_lg_3 = "mx-lg-3",
    mx_lg_4 = "mx-lg-4",
    mx_lg_5 = "mx-lg-5",
    mx_lg_auto = "mx-lg-auto",
    my_lg_0 = "my-lg-0",
    my_lg_1 = "my-lg-1",
    my_lg_2 = "my-lg-2",
    my_lg_3 = "my-lg-3",
    my_lg_4 = "my-lg-4",
    my_lg_5 = "my-lg-5",
    my_lg_auto = "my-lg-auto",
    mt_lg_0 = "mt-lg-0",
    mt_lg_1 = "mt-lg-1",
    mt_lg_2 = "mt-lg-2",
    mt_lg_3 = "mt-lg-3",
    mt_lg_4 = "mt-lg-4",
    mt_lg_5 = "mt-lg-5",
    mt_lg_auto = "mt-lg-auto",
    me_lg_0 = "me-lg-0",
    me_lg_1 = "me-lg-1",
    me_lg_2 = "me-lg-2",
    me_lg_3 = "me-lg-3",
    me_lg_4 = "me-lg-4",
    me_lg_5 = "me-lg-5",
    me_lg_auto = "me-lg-auto",
    mb_lg_0 = "mb-lg-0",
    mb_lg_1 = "mb-lg-1",
    mb_lg_2 = "mb-lg-2",
    mb_lg_3 = "mb-lg-3",
    mb_lg_4 = "mb-lg-4",
    mb_lg_5 = "mb-lg-5",
    mb_lg_auto = "mb-lg-auto",
    ms_lg_0 = "ms-lg-0",
    ms_lg_1 = "ms-lg-1",
    ms_lg_2 = "ms-lg-2",
    ms_lg_3 = "ms-lg-3",
    ms_lg_4 = "ms-lg-4",
    ms_lg_5 = "ms-lg-5",
    ms_lg_auto = "ms-lg-auto",
    p_lg_0 = "p-lg-0",
    p_lg_1 = "p-lg-1",
    p_lg_2 = "p-lg-2",
    p_lg_3 = "p-lg-3",
    p_lg_4 = "p-lg-4",
    p_lg_5 = "p-lg-5",
    px_lg_0 = "px-lg-0",
    px_lg_1 = "px-lg-1",
    px_lg_2 = "px-lg-2",
    px_lg_3 = "px-lg-3",
    px_lg_4 = "px-lg-4",
    px_lg_5 = "px-lg-5",
    py_lg_0 = "py-lg-0",
    py_lg_1 = "py-lg-1",
    py_lg_2 = "py-lg-2",
    py_lg_3 = "py-lg-3",
    py_lg_4 = "py-lg-4",
    py_lg_5 = "py-lg-5",
    pt_lg_0 = "pt-lg-0",
    pt_lg_1 = "pt-lg-1",
    pt_lg_2 = "pt-lg-2",
    pt_lg_3 = "pt-lg-3",
    pt_lg_4 = "pt-lg-4",
    pt_lg_5 = "pt-lg-5",
    pe_lg_0 = "pe-lg-0",
    pe_lg_1 = "pe-lg-1",
    pe_lg_2 = "pe-lg-2",
    pe_lg_3 = "pe-lg-3",
    pe_lg_4 = "pe-lg-4",
    pe_lg_5 = "pe-lg-5",
    pb_lg_0 = "pb-lg-0",
    pb_lg_1 = "pb-lg-1",
    pb_lg_2 = "pb-lg-2",
    pb_lg_3 = "pb-lg-3",
    pb_lg_4 = "pb-lg-4",
    pb_lg_5 = "pb-lg-5",
    ps_lg_0 = "ps-lg-0",
    ps_lg_1 = "ps-lg-1",
    ps_lg_2 = "ps-lg-2",
    ps_lg_3 = "ps-lg-3",
    ps_lg_4 = "ps-lg-4",
    ps_lg_5 = "ps-lg-5",
    gap_lg_0 = "gap-lg-0",
    gap_lg_1 = "gap-lg-1",
    gap_lg_2 = "gap-lg-2",
    gap_lg_3 = "gap-lg-3",
    gap_lg_4 = "gap-lg-4",
    gap_lg_5 = "gap-lg-5",
    text_lg_start = "text-lg-start",
    text_lg_end = "text-lg-end",
    text_lg_center = "text-lg-center",
    float_xl_start = "float-xl-start",
    float_xl_end = "float-xl-end",
    float_xl_none = "float-xl-none",
    d_xl_inline = "d-xl-inline",
    d_xl_inline_block = "d-xl-inline-block",
    d_xl_block = "d-xl-block",
    d_xl_grid = "d-xl-grid",
    d_xl_table = "d-xl-table",
    d_xl_table_row = "d-xl-table-row",
    d_xl_table_cell = "d-xl-table-cell",
    d_xl_flex = "d-xl-flex",
    d_xl_inline_flex = "d-xl-inline-flex",
    d_xl_none = "d-xl-none",
    flex_xl_fill = "flex-xl-fill",
    flex_xl_row = "flex-xl-row",
    flex_xl_column = "flex-xl-column",
    flex_xl_row_reverse = "flex-xl-row-reverse",
    flex_xl_column_reverse = "flex-xl-column-reverse",
    flex_xl_grow_0 = "flex-xl-grow-0",
    flex_xl_grow_1 = "flex-xl-grow-1",
    flex_xl_shrink_0 = "flex-xl-shrink-0",
    flex_xl_shrink_1 = "flex-xl-shrink-1",
    flex_xl_wrap = "flex-xl-wrap",
    flex_xl_nowrap = "flex-xl-nowrap",
    flex_xl_wrap_reverse = "flex-xl-wrap-reverse",
    justify_content_xl_start = "justify-content-xl-start",
    justify_content_xl_end = "justify-content-xl-end",
    justify_content_xl_center = "justify-content-xl-center",
    justify_content_xl_between = "justify-content-xl-between",
    justify_content_xl_around = "justify-content-xl-around",
    justify_content_xl_evenly = "justify-content-xl-evenly",
    align_items_xl_start = "align-items-xl-start",
    align_items_xl_end = "align-items-xl-end",
    align_items_xl_center = "align-items-xl-center",
    align_items_xl_baseline = "align-items-xl-baseline",
    align_items_xl_stretch = "align-items-xl-stretch",
    align_content_xl_start = "align-content-xl-start",
    align_content_xl_end = "align-content-xl-end",
    align_content_xl_center = "align-content-xl-center",
    align_content_xl_between = "align-content-xl-between",
    align_content_xl_around = "align-content-xl-around",
    align_content_xl_stretch = "align-content-xl-stretch",
    align_self_xl_auto = "align-self-xl-auto",
    align_self_xl_start = "align-self-xl-start",
    align_self_xl_end = "align-self-xl-end",
    align_self_xl_center = "align-self-xl-center",
    align_self_xl_baseline = "align-self-xl-baseline",
    align_self_xl_stretch = "align-self-xl-stretch",
    order_xl_first = "order-xl-first",
    order_xl_0 = "order-xl-0",
    order_xl_1 = "order-xl-1",
    order_xl_2 = "order-xl-2",
    order_xl_3 = "order-xl-3",
    order_xl_4 = "order-xl-4",
    order_xl_5 = "order-xl-5",
    order_xl_last = "order-xl-last",
    m_xl_0 = "m-xl-0",
    m_xl_1 = "m-xl-1",
    m_xl_2 = "m-xl-2",
    m_xl_3 = "m-xl-3",
    m_xl_4 = "m-xl-4",
    m_xl_5 = "m-xl-5",
    m_xl_auto = "m-xl-auto",
    mx_xl_0 = "mx-xl-0",
    mx_xl_1 = "mx-xl-1",
    mx_xl_2 = "mx-xl-2",
    mx_xl_3 = "mx-xl-3",
    mx_xl_4 = "mx-xl-4",
    mx_xl_5 = "mx-xl-5",
    mx_xl_auto = "mx-xl-auto",
    my_xl_0 = "my-xl-0",
    my_xl_1 = "my-xl-1",
    my_xl_2 = "my-xl-2",
    my_xl_3 = "my-xl-3",
    my_xl_4 = "my-xl-4",
    my_xl_5 = "my-xl-5",
    my_xl_auto = "my-xl-auto",
    mt_xl_0 = "mt-xl-0",
    mt_xl_1 = "mt-xl-1",
    mt_xl_2 = "mt-xl-2",
    mt_xl_3 = "mt-xl-3",
    mt_xl_4 = "mt-xl-4",
    mt_xl_5 = "mt-xl-5",
    mt_xl_auto = "mt-xl-auto",
    me_xl_0 = "me-xl-0",
    me_xl_1 = "me-xl-1",
    me_xl_2 = "me-xl-2",
    me_xl_3 = "me-xl-3",
    me_xl_4 = "me-xl-4",
    me_xl_5 = "me-xl-5",
    me_xl_auto = "me-xl-auto",
    mb_xl_0 = "mb-xl-0",
    mb_xl_1 = "mb-xl-1",
    mb_xl_2 = "mb-xl-2",
    mb_xl_3 = "mb-xl-3",
    mb_xl_4 = "mb-xl-4",
    mb_xl_5 = "mb-xl-5",
    mb_xl_auto = "mb-xl-auto",
    ms_xl_0 = "ms-xl-0",
    ms_xl_1 = "ms-xl-1",
    ms_xl_2 = "ms-xl-2",
    ms_xl_3 = "ms-xl-3",
    ms_xl_4 = "ms-xl-4",
    ms_xl_5 = "ms-xl-5",
    ms_xl_auto = "ms-xl-auto",
    p_xl_0 = "p-xl-0",
    p_xl_1 = "p-xl-1",
    p_xl_2 = "p-xl-2",
    p_xl_3 = "p-xl-3",
    p_xl_4 = "p-xl-4",
    p_xl_5 = "p-xl-5",
    px_xl_0 = "px-xl-0",
    px_xl_1 = "px-xl-1",
    px_xl_2 = "px-xl-2",
    px_xl_3 = "px-xl-3",
    px_xl_4 = "px-xl-4",
    px_xl_5 = "px-xl-5",
    py_xl_0 = "py-xl-0",
    py_xl_1 = "py-xl-1",
    py_xl_2 = "py-xl-2",
    py_xl_3 = "py-xl-3",
    py_xl_4 = "py-xl-4",
    py_xl_5 = "py-xl-5",
    pt_xl_0 = "pt-xl-0",
    pt_xl_1 = "pt-xl-1",
    pt_xl_2 = "pt-xl-2",
    pt_xl_3 = "pt-xl-3",
    pt_xl_4 = "pt-xl-4",
    pt_xl_5 = "pt-xl-5",
    pe_xl_0 = "pe-xl-0",
    pe_xl_1 = "pe-xl-1",
    pe_xl_2 = "pe-xl-2",
    pe_xl_3 = "pe-xl-3",
    pe_xl_4 = "pe-xl-4",
    pe_xl_5 = "pe-xl-5",
    pb_xl_0 = "pb-xl-0",
    pb_xl_1 = "pb-xl-1",
    pb_xl_2 = "pb-xl-2",
    pb_xl_3 = "pb-xl-3",
    pb_xl_4 = "pb-xl-4",
    pb_xl_5 = "pb-xl-5",
    ps_xl_0 = "ps-xl-0",
    ps_xl_1 = "ps-xl-1",
    ps_xl_2 = "ps-xl-2",
    ps_xl_3 = "ps-xl-3",
    ps_xl_4 = "ps-xl-4",
    ps_xl_5 = "ps-xl-5",
    gap_xl_0 = "gap-xl-0",
    gap_xl_1 = "gap-xl-1",
    gap_xl_2 = "gap-xl-2",
    gap_xl_3 = "gap-xl-3",
    gap_xl_4 = "gap-xl-4",
    gap_xl_5 = "gap-xl-5",
    text_xl_start = "text-xl-start",
    text_xl_end = "text-xl-end",
    text_xl_center = "text-xl-center",
    float_xxl_start = "float-xxl-start",
    float_xxl_end = "float-xxl-end",
    float_xxl_none = "float-xxl-none",
    d_xxl_inline = "d-xxl-inline",
    d_xxl_inline_block = "d-xxl-inline-block",
    d_xxl_block = "d-xxl-block",
    d_xxl_grid = "d-xxl-grid",
    d_xxl_table = "d-xxl-table",
    d_xxl_table_row = "d-xxl-table-row",
    d_xxl_table_cell = "d-xxl-table-cell",
    d_xxl_flex = "d-xxl-flex",
    d_xxl_inline_flex = "d-xxl-inline-flex",
    d_xxl_none = "d-xxl-none",
    flex_xxl_fill = "flex-xxl-fill",
    flex_xxl_row = "flex-xxl-row",
    flex_xxl_column = "flex-xxl-column",
    flex_xxl_row_reverse = "flex-xxl-row-reverse",
    flex_xxl_column_reverse = "flex-xxl-column-reverse",
    flex_xxl_grow_0 = "flex-xxl-grow-0",
    flex_xxl_grow_1 = "flex-xxl-grow-1",
    flex_xxl_shrink_0 = "flex-xxl-shrink-0",
    flex_xxl_shrink_1 = "flex-xxl-shrink-1",
    flex_xxl_wrap = "flex-xxl-wrap",
    flex_xxl_nowrap = "flex-xxl-nowrap",
    flex_xxl_wrap_reverse = "flex-xxl-wrap-reverse",
    justify_content_xxl_start = "justify-content-xxl-start",
    justify_content_xxl_end = "justify-content-xxl-end",
    justify_content_xxl_center = "justify-content-xxl-center",
    justify_content_xxl_between = "justify-content-xxl-between",
    justify_content_xxl_around = "justify-content-xxl-around",
    justify_content_xxl_evenly = "justify-content-xxl-evenly",
    align_items_xxl_start = "align-items-xxl-start",
    align_items_xxl_end = "align-items-xxl-end",
    align_items_xxl_center = "align-items-xxl-center",
    align_items_xxl_baseline = "align-items-xxl-baseline",
    align_items_xxl_stretch = "align-items-xxl-stretch",
    align_content_xxl_start = "align-content-xxl-start",
    align_content_xxl_end = "align-content-xxl-end",
    align_content_xxl_center = "align-content-xxl-center",
    align_content_xxl_between = "align-content-xxl-between",
    align_content_xxl_around = "align-content-xxl-around",
    align_content_xxl_stretch = "align-content-xxl-stretch",
    align_self_xxl_auto = "align-self-xxl-auto",
    align_self_xxl_start = "align-self-xxl-start",
    align_self_xxl_end = "align-self-xxl-end",
    align_self_xxl_center = "align-self-xxl-center",
    align_self_xxl_baseline = "align-self-xxl-baseline",
    align_self_xxl_stretch = "align-self-xxl-stretch",
    order_xxl_first = "order-xxl-first",
    order_xxl_0 = "order-xxl-0",
    order_xxl_1 = "order-xxl-1",
    order_xxl_2 = "order-xxl-2",
    order_xxl_3 = "order-xxl-3",
    order_xxl_4 = "order-xxl-4",
    order_xxl_5 = "order-xxl-5",
    order_xxl_last = "order-xxl-last",
    m_xxl_0 = "m-xxl-0",
    m_xxl_1 = "m-xxl-1",
    m_xxl_2 = "m-xxl-2",
    m_xxl_3 = "m-xxl-3",
    m_xxl_4 = "m-xxl-4",
    m_xxl_5 = "m-xxl-5",
    m_xxl_auto = "m-xxl-auto",
    mx_xxl_0 = "mx-xxl-0",
    mx_xxl_1 = "mx-xxl-1",
    mx_xxl_2 = "mx-xxl-2",
    mx_xxl_3 = "mx-xxl-3",
    mx_xxl_4 = "mx-xxl-4",
    mx_xxl_5 = "mx-xxl-5",
    mx_xxl_auto = "mx-xxl-auto",
    my_xxl_0 = "my-xxl-0",
    my_xxl_1 = "my-xxl-1",
    my_xxl_2 = "my-xxl-2",
    my_xxl_3 = "my-xxl-3",
    my_xxl_4 = "my-xxl-4",
    my_xxl_5 = "my-xxl-5",
    my_xxl_auto = "my-xxl-auto",
    mt_xxl_0 = "mt-xxl-0",
    mt_xxl_1 = "mt-xxl-1",
    mt_xxl_2 = "mt-xxl-2",
    mt_xxl_3 = "mt-xxl-3",
    mt_xxl_4 = "mt-xxl-4",
    mt_xxl_5 = "mt-xxl-5",
    mt_xxl_auto = "mt-xxl-auto",
    me_xxl_0 = "me-xxl-0",
    me_xxl_1 = "me-xxl-1",
    me_xxl_2 = "me-xxl-2",
    me_xxl_3 = "me-xxl-3",
    me_xxl_4 = "me-xxl-4",
    me_xxl_5 = "me-xxl-5",
    me_xxl_auto = "me-xxl-auto",
    mb_xxl_0 = "mb-xxl-0",
    mb_xxl_1 = "mb-xxl-1",
    mb_xxl_2 = "mb-xxl-2",
    mb_xxl_3 = "mb-xxl-3",
    mb_xxl_4 = "mb-xxl-4",
    mb_xxl_5 = "mb-xxl-5",
    mb_xxl_auto = "mb-xxl-auto",
    ms_xxl_0 = "ms-xxl-0",
    ms_xxl_1 = "ms-xxl-1",
    ms_xxl_2 = "ms-xxl-2",
    ms_xxl_3 = "ms-xxl-3",
    ms_xxl_4 = "ms-xxl-4",
    ms_xxl_5 = "ms-xxl-5",
    ms_xxl_auto = "ms-xxl-auto",
    p_xxl_0 = "p-xxl-0",
    p_xxl_1 = "p-xxl-1",
    p_xxl_2 = "p-xxl-2",
    p_xxl_3 = "p-xxl-3",
    p_xxl_4 = "p-xxl-4",
    p_xxl_5 = "p-xxl-5",
    px_xxl_0 = "px-xxl-0",
    px_xxl_1 = "px-xxl-1",
    px_xxl_2 = "px-xxl-2",
    px_xxl_3 = "px-xxl-3",
    px_xxl_4 = "px-xxl-4",
    px_xxl_5 = "px-xxl-5",
    py_xxl_0 = "py-xxl-0",
    py_xxl_1 = "py-xxl-1",
    py_xxl_2 = "py-xxl-2",
    py_xxl_3 = "py-xxl-3",
    py_xxl_4 = "py-xxl-4",
    py_xxl_5 = "py-xxl-5",
    pt_xxl_0 = "pt-xxl-0",
    pt_xxl_1 = "pt-xxl-1",
    pt_xxl_2 = "pt-xxl-2",
    pt_xxl_3 = "pt-xxl-3",
    pt_xxl_4 = "pt-xxl-4",
    pt_xxl_5 = "pt-xxl-5",
    pe_xxl_0 = "pe-xxl-0",
    pe_xxl_1 = "pe-xxl-1",
    pe_xxl_2 = "pe-xxl-2",
    pe_xxl_3 = "pe-xxl-3",
    pe_xxl_4 = "pe-xxl-4",
    pe_xxl_5 = "pe-xxl-5",
    pb_xxl_0 = "pb-xxl-0",
    pb_xxl_1 = "pb-xxl-1",
    pb_xxl_2 = "pb-xxl-2",
    pb_xxl_3 = "pb-xxl-3",
    pb_xxl_4 = "pb-xxl-4",
    pb_xxl_5 = "pb-xxl-5",
    ps_xxl_0 = "ps-xxl-0",
    ps_xxl_1 = "ps-xxl-1",
    ps_xxl_2 = "ps-xxl-2",
    ps_xxl_3 = "ps-xxl-3",
    ps_xxl_4 = "ps-xxl-4",
    ps_xxl_5 = "ps-xxl-5",
    gap_xxl_0 = "gap-xxl-0",
    gap_xxl_1 = "gap-xxl-1",
    gap_xxl_2 = "gap-xxl-2",
    gap_xxl_3 = "gap-xxl-3",
    gap_xxl_4 = "gap-xxl-4",
    gap_xxl_5 = "gap-xxl-5",
    text_xxl_start = "text-xxl-start",
    text_xxl_end = "text-xxl-end",
    text_xxl_center = "text-xxl-center",
    d_print_inline = "d-print-inline",
    d_print_inline_block = "d-print-inline-block",
    d_print_block = "d-print-block",
    d_print_grid = "d-print-grid",
    d_print_table = "d-print-table",
    d_print_table_row = "d-print-table-row",
    d_print_table_cell = "d-print-table-cell",
    d_print_flex = "d-print-flex",
    d_print_inline_flex = "d-print-inline-flex",
    d_print_none = "d-print-none",
}
